import firebase from 'firebase/app';
import 'firebase/firestore';

// Initialize Firebase
let config = {
    apiKey: "AIzaSyAkD26bys24M42KLR0ftcOKYEKvQhYDcT8",
    authDomain: "smartdigital-3d98a.firebaseapp.com",
    databaseURL: "https://smartdigital-3d98a.firebaseio.com",
    projectId: "smartdigital-3d98a",
    storageBucket: "smartdigital-3d98a.appspot.com",
    messagingSenderId: "388369798962"
};
firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;