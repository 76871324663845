import React from 'react'
import { Route } from 'react-router-dom'
import Home from '../home'
import Slider from '../slider'

const App = () => (
  <div>
    {/*<header>
      <Link to="/">Home</Link>
      <Link to="/about-us">About</Link>
    </header>*/}

    <main>
        <Route exact path="/" component={Home} />
        <Route path="/slider/:time?/:clock?/:start?" component={Slider} />
    </main>
  </div>
)

export default App
