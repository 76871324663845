import React, { Component } from 'react'
import firebase from "firebase";
import 'firebase/firestore';
import FileUploader from "react-firebase-file-uploader";
import '../../assets/add.svg';
import mainLogo from '../../assets/pb_smart_digital.svg';
import appLogo from '../../assets/smapp_logo.svg';
import Cropper from 'cropperjs';
import '../../../node_modules/cropperjs/dist/cropper.css';
import './home.css';


class Home extends Component {

    state = {
        image: '',
        finalImage: '',
        isUploading: false,
        progress: 0,
        buttonDisabled: false,
        buttonClass: '',
        editClass: '',
        descClass: '',
        description: '',
        label: 'Upload',
        portrait: false
    };

    opts = {
        aspectRatio: 5 / 3,
        dragMode: 'move',
        autoCropArea: 1,
        viewMode: 0,
        cropBoxResizable: true,
    };

    handleChangeImage = (e) => {
        const image = e.target.files[0];
        if (image) {
            let img = new Image();
            img.src = window.URL.createObjectURL( image );
            img.onload = () => {
                if (this.isPortrait(img)) {
                    this.setState({ portrait: true })
                }
                this.setState({image, editClass: 'is-visible'});
                this.cropper = new Cropper(document.getElementById('preview'), this.opts);
            }
        }
    };

    isPortrait = (img) => {
        let w = img.naturalWidth || img.width,
            h = img.naturalHeight || img.height;
        return (h > w);
    };

    resetState = () => {
        this.setState({
            image: '',
            finalImage: '',
            isUploading: false,
            progress: 0,
            buttonDisabled: false,
            buttonClass: '',
            editClass: '',
            descClass: '',
            description: '',
            label: 'Upload',
            portrait: false
        })
    };


    handleUploadStart = () => this.setState({ isUploading: true, progress: 0, buttonDisabled: true, label: 'Uploading...' });

    handleProgress = progress => {
        this.setState({ progress });
    }

    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = filename => {
        let parent = this;
        this.setState({ progress: 100, isUploading: false, buttonClass: 'completed' });
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => {
                firebase.firestore().collection('images').add({'url' : url, 'caption' : this.state.description, 'updatedAt': new Date(), 'portrait': this.state.portrait});
                // setTimeout(() => {parent.resetState()}, 1000);
                this.setState({descClass: 'is-visible is-completed'});
            });

        //
    };


    handleSubmit = (e) => {
        e.preventDefault();
        if (this.uploader && this.state.finalImage) {
            this.uploader.startUpload(this.state.finalImage);
        }
    }

    handleTextAreaChange = event => {
        this.setState({ description: event.target.value });
    }

    getPreviewImage = (e) => {
        e.preventDefault();
        this.cropper.getCroppedCanvas({  width: 800, height: 480 }).toBlob((blob) => {
            this.setState({
                finalImage: blob,
                editClass: '',
                descClass: 'is-visible'
            });
        }, 'image/jpeg');
    }

    openPreviewEditor = () => {
        this.setState({
            editClass: 'is-visible',
            descClass: ''
        })
    }



    render() {
        return (
            <div className="wrap">

                <div className="main-logo">
                    <img src={appLogo} alt=""/>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <label htmlFor="upload"></label>
                    <FileUploader
                        id="upload"
                        accept="image/*"
                        name="image"
                        randomizeFilename
                        ref={c => { this.uploader = c; }}
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        onChange={this.handleChangeImage}
                    />
                    <div className={`desc-container ${this.state.descClass}`}>
                        <div className="image-container">
                            { this.state.finalImage && (
                                <img src={URL.createObjectURL(this.state.finalImage)} alt="" onClick={this.openPreviewEditor}/>
                            ) }
                        </div>
                        <div className="text-container">
                            <textarea name="text" id="text" cols="30" rows="10" placeholder="Caption ... " onChange={this.handleTextAreaChange} value={this.state.description}></textarea>
                            <button onClick={this.handleSubmit} className={`upload-button ${this.state.buttonClass}`} disabled={this.state.buttonDisabled} data-progress={this.state.progress}></button>
                            <span>{this.state.label}</span>
                            <button className="reset-button" onClick={this.resetState}>Upload another one?</button>
                        </div>
                    </div>
                    <div className={`edit-container ${this.state.editClass}`}>
                        { this.state.image && (
                            <div>
                                <h2 className="edit-title">Edit image</h2>
                                <div className="image-container">
                                    <img src={URL.createObjectURL(this.state.image)} id='preview' alt=""/>
                                </div>
                                <button className="def-button" onClick={this.getPreviewImage}>Use image</button>
                            </div>
                        )}
                    </div>
                </form>
                <img src={mainLogo} alt="" className="footer-logo"/>
            </div>
        )
    }
}

export default Home;