import React, { Component } from "react";
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import './slider.css';
import ReactInterval from 'react-interval';
import _ from 'lodash';
import Clock from 'react-live-clock';

class Slider extends Component {

    constructor(props) {
        super(props);

        this.slides = [];
        this.state = {
            sliderClass: '',
            currentSlide: 0,
            enabled: false,
            timeout: (props.match.params.time || 5),
            maxSlide: 0,
            autostarted: false,
            showClock: (props.match.params.clock === 'on') ? 'show' : ''
        }

        document.addEventListener('contextmenu', (ev) => {
            ev.preventDefault();
            this.stopSlider();
            return false;
        }, false);


    };


    componentDidUpdate() {
        if (this.props.slides.length > this.state.maxSlide && this.state.enabled) {
            this.setState({
               maxSlide: this.props.slides.length,
               currentSlide: this.props.slides.length - 1,
               enabled: false
            });
            setTimeout(() => {
                this.setState({
                    enabled: true
                })
            }, this.state.timeout * 1000);
        }

        if (this.props.match.params.start === 'autostart' && !this.state.autostarted) {
            this.setState({
                autostarted: true,
                sliderClass: 'is-visible',
                enabled: true
            });
        }

    }

    slideElem = (slide, key) => {
        let className = (slide.portrait) ? 'slide portrait' : 'slide';
        let showClass = (key === this.state.currentSlide) ? 'show' : '';
        return (
            <div className={className + ' ' + showClass} key={key}>
                /*<div style={{ backgroundImage: `url(${slide.url})`}}></div>*/
                <img src={slide.url} alt=""/>
                <span>{slide.caption}</span>
            </div>
        )
    };

    startSlider = () => {
        this.setState({
            sliderClass: 'is-visible',
            enabled: true
        });
    };

    stopSlider = () => {
        this.setState({
            sliderClass: '',
            enabled: false
        });
    };

    onChangeTiming = ({target: {value}}) => {
        this.setState({timeout: (value.length > 0 && !isNaN(value)) ? parseInt(value, 10) : ''});
    };
    
    onChangeClock = ({target: {checked}}) => {
        this.setState({ showClock : checked })
    };


    render() {
        if (typeof this.props.slides !== 'undefined') {

            const { timeout, enabled } = this.state;
            let oh = { overflow: 'hidden'};
            return (
                <div style={oh} >
                    <ReactInterval timeout={timeout * 1000} enabled={enabled}
                                   callback={() => this.setState({currentSlide: (this.state.currentSlide === this.props.slides.length - 1) ? 0 : this.state.currentSlide + 1})} />
                    <div className="settings">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Slider timing (sec)</td>
                                    <td><input type="text" value={timeout} onChange={this.onChangeTiming} /></td>
                                </tr>
                                <tr>
                                    <td>Show clock</td>
                                    <td>
                                        <label className="toggleSwitch nolabel large">
                                            <input type="checkbox" onChange={this.onChangeClock} checked={this.state.showClock}/>
                                            <span>
                                                <span>OFF</span>
                                                <span>ON</span>
                                            </span>
                                            <a></a>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button onClick={this.startSlider}>Start slider</button>
                    </div>
                    <div className={`slider ${this.state.sliderClass}`} >
                        {
                            this.props.slides.map((slide, i) => this.slideElem(slide, i))
                        }
                        <div className={`clock ${(this.state.showClock) ? 'show' : ''}`}>
                            <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Berlin'} interval={5000} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return ( <div></div> )
        }


    }
}

const mapStateToProps = (state) => {
    return {
        slides: state.firestore.ordered.images
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([{ collection: 'images', orderBy: ['updatedAt', 'asc'] }])
)(Slider);
